import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="page-not-found-wrapper">
      <div className="page-not-found">
        <h1>404: Not Found</h1>
        <p>이 페이지는 없는 페이지 입니다. 다른 페이지를 보시려면 메뉴에 있는 링크를 이용해주세요 :)</p>
        <p>The page does not exist. Please use the other links on the navigation bar :)</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
